<template>
  <div class="sidebar-wrapper d-flex flex-column flex-grow-1">
    <div v-if="users" ref="planningUser" class="px-2 pb-2 sidebar-inner border">
      <div class="planning-search">
        <b-input-group class="input-group-merge w-100 round">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" class="text-muted" />
          </b-input-group-prepend>
          <b-form-input v-model="searchQuery" placeholder="Rechercher..." />
        </b-input-group>
      </div>
      <div v-for="(user, index) in filteredUsers" :key="index" :data-user-id="user.id"
           :data-user-full-name="`${user.familyName} ${user.givenName}`"
           class="user p-1 d-flex align-items-center fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event event-drag mt-2"
           @touchstart="emitSidebarActive"
      >
        <initials-avatar v-if="user"
                         size="30"
                         :family-name="user.familyName"
                         :given-name="user.givenName"
                         :show-title="true" />
        <div class="user-name text-nowrap ml-2 font-weight-bolder">
          {{ user.familyName }} {{ user.givenName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BInputGroup, BInputGroupPrepend, BFormInput } from 'bootstrap-vue'
import InitialsAvatar from '@/components/InitialsAvatar.vue'
import store from '@/store'
import {
  ref,
  computed,
  onMounted,
} from '@vue/composition-api'
import { Draggable } from '@fullcalendar/interaction'
import SearchMatcher from '@/utils/SearchMatcher'

export default {
  components: {
    InitialsAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },
  setup() {
    const planningUser = ref(null)

    onMounted(() => new Draggable(planningUser.value, {
        itemSelector: '.user',
        eventData(eventEl) {
          return {
            id: eventEl.getAttribute('data-user-id'),
            title: eventEl.getAttribute('data-user-full-name'),
            extendedProps: { type: 'intervenant' },
          }
        },
      }))

    const users = computed(() => store.getters['users/users'])
    const usersAvailable = computed(() => store.getters['calendar/usersAvailable'])
    const searchQuery = ref('')
    const searchMatcher = computed(() => new SearchMatcher(searchQuery.value))
    const filteredUsers = computed(() => {
      if (usersAvailable.value) {
        return usersAvailable.value.filter(y => searchMatcher.value.match(
          y.familyName,
          y.givenName,
          y.email,
        ))
      }
      return []
    })

    return {
      users,
      usersAvailable,
      planningUser,
      searchQuery,
      filteredUsers,
    }
  },
  methods: {
    emitSidebarActive() {
      this.longPressTimeout = setTimeout(() => {
        this.$emit('sidebar-active')
      }, 1000)
    },
  },
}
</script>

<style scoped lang="scss">
.user-name {
  color: white;
}

.sidebar-inner {
  position: sticky;
  top: 62px;
  max-height: calc(100vh - 62px);
  overflow-y: auto;
  overflow-x: hidden;
}

.planning-search {
  margin-top: 1rem;
}

</style>
